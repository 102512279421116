/**
 * LM-LIFF-05 綁定流程-綁定完成 https://lmliff.cht.com.tw/binding-success
 */
import { useCallback, useMemo } from 'react';

import imgBindingSuccess from '@/assets/images/img-binding-success.svg';
import StandardButton from '@/components/StandardButton';
import useLiff from '@/hooks/useLiff';
import ModuleFlowResult from '@/modules/ModuleFlowResult';
import SVG from '@/patterns/SVG';
import { PropTypes } from '@/utils/propTypes';
import { styled } from '@/utils/styledComponents';

const defaultTitle = '恭喜成為LINE MOBILE用戶\n並且綁定成功';
const defaultMessage = '您已領取過優惠\n可至LINE MOBILE官方帳號確認';

const StyledImage = styled(SVG)`
  margin-top: 34px;
  margin-bottom: 52px;
`;

const renderImage = () => {
  return <StyledImage src={imgBindingSuccess} />;
};

const BindingSuccessPage = (
  /** @type {{location: {state: { title: string, message: string}}}} */ {
    location,
  }
) => {
  const { title, message } = useMemo(
    () =>
      (typeof window !== 'undefined' && location.state) ?? {
        title: '',
        message: '',
      },

    [location]
  );

  const { quitLiff } = useLiff();
  /**
   * onConfirmToGo 確認按鈕
   * 離開LIFF
   */
  const onConfirmToGo = useCallback(() => quitLiff(), [quitLiff]);

  const renderButtons = () => (
    <>
      <StandardButton type="primary" onClick={onConfirmToGo}>
        確定
      </StandardButton>
    </>
  );
  return (
    <ModuleFlowResult
      title={title || defaultTitle}
      message={message || defaultMessage}
      renderImage={renderImage}
      renderButtons={renderButtons}
      fixedTextHeight
    />
  );
};

BindingSuccessPage.propTypes = {
  location: PropTypes.object,
};

export default BindingSuccessPage;
